<template>
  <div>
    <section class="block inline-grid-container container-padding">
      <div class="block-title">
        <h3>{{$t('fundProjects')}}</h3>
        <CustomLink :title="$t('allProjects')" font-size="14px" link="/projects"/>
      </div>
      <div :class="[{page: isOneProjectPage}, 'main-project-list']">
        <ProjectItem v-for="el in filterProjects" :key="el.id" :project="el" class="overlayimage"
                     main-projects @openProject="openProject(el)"/>
      </div>
      <div :class="[{page: isOneProjectPage}, 'projects-wrapper', 'block-content', 'dynamic-content']">
        <swiper
          ref="projectsSwiper" :options="swiperOptions" class="swiper"
          :delete-instance-on-destroy="true"
          :cleanup-styles-on-destroy="true"
        >
          <swiper-slide v-for="el in filteredProjects" :key="el.id" class="slider__slide">
            <ProjectItem :project="el" margin @openProject="openProject(el)"/>
          </swiper-slide>
          <div class="swiper-scrollbar" slot="pagination"></div>
        </swiper>
      </div>
    </section>
    <div class="separator"></div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ProjectItem from '../../../components/ProjectItem.vue';
import CustomLink from '../../../components/UI/CustomLink.vue';

export default {
  name: 'Projects',

  computed: {
    ...mapState('projects', ['mainProjects']),
    ...mapState(['swiperOptions']),
    filterProjects() {
      const newArr = this.mainProjects.map(a => ({...a}));
      if(newArr.length > 0) {
        newArr.sort((a, b) => b.big_block - a.big_block)
        newArr[0].big_block = true
        for (let i = 1; i < newArr.length; i++) {
          newArr[i].big_block = false
        }
      }
      return newArr
    },
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    filteredProjects() {
      const newArr = this.mainProjects.map(a => ({...a}));
      if (newArr.length > 0) {
        for (let i = 0; i < newArr.length; i++) {
          newArr[i].big_block = false
        }
      }
      return newArr
    },
  },

  props: {
    isOneProjectPage: {
      type: Boolean,
      default: false,
    }
  },
  async mounted() {
    if (this.mainProjects.length === 0) {
      await this.getProjects({page: 1, pageSize: 5})
    }
  },

  methods: {
    ...mapActions('projects', ['getProjects']),
    openProject(el) {
      this.$router.push(`/projects/project/${el.id}`)
    },
  },

  components: { ProjectItem, CustomLink, Swiper, SwiperSlide },
};
</script>

<style scoped lang="scss">
.main-project-list {
  grid-column: 5 / 13;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
  grid-auto-rows: 310px;
  grid-auto-flow: row dense;
}

.page.main-project-list{
  display: none;
}

.page.projects-wrapper{
  display: block;
}

@media (max-width: 1210px) {
  .main-project-list {
    grid-auto-rows: 231px;
  }
}

.projects-wrapper {
  display: none;
  width: 100%;
 }

div.overlayimage > div:first-child > div.v-image__image {
   &:hover {
     color: red;
     background-color: red;
 }
}

.v-responsive__content:hover {
  color: red;
  background-color: red;
}


@media (max-width: 1100px) {
  .projects-wrapper {
    display: block;
  }
  .main-project-list {
    display: none;
  }

  .block {
    margin-bottom: 32px;
  }
}

@media (min-width: 1101px) {
  .separator {
    display: none;
  }

  .page {
    .slider__slide {
      flex-shrink: 1;
      min-width: 480px;
      margin-bottom: 56px;
    }
  }

}

.separator {
  margin-left: 6%;
  margin-bottom: 50px;
}

</style>
