<template>
  <section class="fade" v-if="!loading">
    <Main />
    <Competence />
<!--    <v-lazy :options="lazyOptions" min-height="50" transition="fade-transition">-->
      <Projects />
<!--    </v-lazy>-->
<!--    <v-lazy :options="lazyOptions" min-height="50" transition="fade-transition" >-->
      <MediaProjects />
<!--    </v-lazy>-->
<!--    <v-lazy :options="lazyOptions" min-height="50" transition="fade-transition">-->
      <AboutFond />
<!--    </v-lazy>-->
<!--    <v-lazy :options="lazyOptions" min-height="50" transition="fade-transition">-->
      <Team />
<!--    </v-lazy>-->
<!--    <v-lazy :options="lazyOptions" min-height="50" transition="fade-transition">-->
      <News />
<!--    </v-lazy>-->
<!--    <v-lazy :options="lazyOptions" min-height="50" transition="fade-transition">-->
      <ContactsBlock @changeFeedback="changeFeedback"
                :phone="baseInfo.phone" :address="baseInfo.address" :email="baseInfo.email"
      />
<!--    </v-lazy>-->
<!--    <Feedback :isFeedback="isFeedback" @changeFeedback="changeFeedback"/>-->
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Main from './components/Main.vue';
import Feedback from '../../components/Feedback.vue';
import Competence from './components/Competence.vue';
import Projects from './components/Projects.vue';
import MediaProjects from './components/MediaProjects.vue';
import AboutFond from './components/AboutFond/AboutFond.vue';
import Team from './components/Team.vue';
import News from './components/News.vue';
import ContactsBlock from './components/ContactsBlock.vue';

export default {
  name: 'MainPage',

  data() {
    return {
      loading: true,
      isFeedback: false,
      lazyOptions: {
        threshold: 0.1,
      },
    }
  },

  computed: {
    ...mapState(['baseInfo', 'scrollY']),
  },

  methods: {
    ...mapActions('projects', ['getProjects']),
    ...mapActions('news', ['getNews', 'getUpgrades']),
    ...mapActions('mediaProjects', ['getVideos']),
    ...mapActions(['getBaseInfo']),
    changeFeedback(val) {
      this.isFeedback = val
    },
  },

  async mounted() {
    if (!this.baseInfo) {
      await this.getBaseInfo()
    }
    await this.getUpgrades()
    this.loading = false
    await this.getProjects({page: 1, pageSize: 5})
    await this.getNews({page: 1, pageSize: 6})
    await this.getVideos({page: 1, pageSize: 6})
  },

  updated() {
    window.scrollTo({ top: this.scrollY, behavior: 'smooth' })
  },

  components: {
    Main,
    Feedback,
    Competence,
    Projects,
    MediaProjects,
    Team,
    AboutFond,
    News,
    ContactsBlock,
  },
};
</script>
