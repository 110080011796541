<template>
  <div>
    <section class="block inline-grid-container container-padding">
      <div class="block-title">
        <h3>{{$t('mediaProjects')}}</h3>
        <CustomLink  :title="$t('allVideos')" link="/media_projects"/>
      </div>
      <div class="news-wrapper block-content dynamic-content">
        <swiper
          ref="mediaSwiper" :options="swiperOptions" class="swiper"
          :delete-instance-on-destroy="true"
          :cleanup-styles-on-destroy="true"
        >
          <swiper-slide v-for="el in filteredVideo" :key="el.id" class="slider__slide">
            <MediaProjectItem :project="el" margin mainVideo/>
          </swiper-slide>
          <div class="swiper-scrollbar" slot="pagination"></div>
        </swiper>
      </div>
    </section>
    <div class="separator"></div>
  </div>

</template>

<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import MediaProjectItem from '@/components/MediaProjectItem.vue';
import CustomLink from '../../../components/UI/CustomLink.vue';

export default {
  name: 'MediaProjects',

  computed: {
    ...mapState('mediaProjects', ['mainVideos']),
    ...mapState(['swiperOptions']),
    swiper() {
      return this.$refs.mediaSwiper.$swiper
    },

    filteredVideo() {
      return this.mainVideos.filter(el => !el.big_block)
    },
  },

  components: { CustomLink, MediaProjectItem, Swiper, SwiperSlide },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.news-wrapper{

}


@media screen and (max-width: 1100px) {
  .news-wrapper{
    max-width: 100%;
  }

  .block {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1101px) {
  .slider__slide {
    max-width: 360px;
  }

  .separator {
    display: none !important;
  }
}

.separator {
  margin-left: 6%;
  margin-bottom: 50px;
}

</style>
